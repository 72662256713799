    /***************
     Yes/No (Disable) Buttons
    ***************/
 
     $("#l-no").click(function() {

        document.getElementById("l-zwart").disabled = true;
        document.getElementById("l-wit").disabled = true;
        document.getElementById("l-grijs").disabled = true;
      })
 
      $("#l-moogue").click(function() {
       document.getElementById("l-zwart").disabled = false;
       document.getElementById("l-wit").disabled = false;
       document.getElementById("l-grijs").disabled = false;
     })
     
     $("#l-nyc").click(function() {
       document.getElementById("l-zwart").disabled = false;
       document.getElementById("l-wit").disabled = false;
       document.getElementById("l-grijs").disabled = false;
     })